/*  ==========================================================================
    FOOTER
    Styling for the lowest part of the website
    ========================================================================== */

.bn-footer {
  position: relative;
  visibility: visible !important;
  z-index: $unit__zi--footer;
  // type 1
  &.bn-footer--v1 {
    @include footer--v1();
  }
  // type 2
  &.bn-footer--v2 {
    @include footer--v2();
  }
  // type 3
  &.bn-footer--v3 {
    @include footer--v3();
  }
  // top references
  .bn-top-references {
    &.bn-top-references--mobile {
      bottom: 40px;
      display: none;
      position: fixed;
      right: 40px;
      z-index: $unit__zi--footer-top-references;
      .bn-top-references__item {
        background-color: $clr__ci--primary;
        border: 1px solid $clr__white;
        border-radius: 100%;
        color: $clr__typo--secondary;
        cursor: pointer;
        font-size: 2.5em;
        height: 50px;
        line-height: 48px;
        margin-top: 10px;
        text-align: center;
        width: 50px;
        .bn-icon {
          &.bn-icon--minus {
            display: none;
          }
        }
        &.bn-top-references__item--visible {
          display: none;
        }
        &.bn-top-references__item--trigger {
          display: none;
          align-items: center;
          justify-content: center;
          &.bn-top-references__item--act {
            .bn-icon {
              &.bn-icon--plus {
                display: none;
              }
              &.bn-icon--minus {
                display: inline-block;
              }
            }
          }
        }
        &:first-child {
          margin-top: 0;
        }
        &:hover {
          background-color: $clr__ci--secondary;
        }
      }
    }
  }
  // cta buttons
  .bn-cta-buttons {
    display: none;
  }
  // print address
  .bn-address-print {
    display: none;
  }
}


/* RESPONSIVE BEHAVIOR
 * --------------------------------------------------------------------------- */

// 370px max height
@media #{$mq__max-h--370} {
  .bn-footer {
    // top references
    .bn-top-references {
      display: none !important;
    }
  }
}

// tablet
@media #{$mq__tablet} {
  .bn-footer {
    // top references
    .bn-top-references {
      &.bn-top-references--mobile {
        &.bn-top-references--visible {
          display: none;

          .bn-top-references__item.book {
            display: none !important;
          }

          .bn-label {
            display: none !important;
          }
        }
      }
    }
    // cta buttons
    .bn-cta-buttons {
      position: fixed;
      bottom: 0;
      width: 100%;
      height: 0;
      display: flex;
      transition: height 0.5s;
      overflow: hidden;
      border-top: 1px solid $clr__white;
      &--visible {
        height: 45px;
        transition: height 0.5s;
      }

      &__item {
        width: 40%;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        color: $clr__white;
        font-size: 1.8em;
        display: flex;

        &:nth-child(1) {
          background: $clr__cta--primary;
          border-right: 1px solid $clr__white;
          &:hover {
            background: #60092a;
          }
        }
        &:nth-child(2) {
          background: $clr__gold;
          border-right: 1px solid $clr__white;
          &:hover {
            background: #ff9300;
          }
        }
        &:last-of-type {
          width: 20%;
          background: $clr__ci--secondary;
          &:hover {
            background: #8b867d;
          }
        }
      }
    }
  }
}

// phablet
@media #{$mq__phablet} {
  .bn-footer {
    // top references
    .bn-top-references {
      &.bn-top-references--mobile {
        bottom: 20px;
        right: 20px;
      }
    }
  }
}