/*  ==========================================================================
    DIVIDER V2
    ========================================================================== */

/*
 * COLORS
 */

$module-clr__divider-v2--line: $clr__ci--primary;

/*
 * MODULE
 */

.bn-divider-v2 {
  .bn-divider-v2__line {
    background-color: $module-clr__divider-v2--line;
    height: 2px;
  }
}


/* RESPONSIVE BEHAVIOR
 * --------------------------------------------------------------------------- */