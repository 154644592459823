/*  ==========================================================================
    IMAGES
    ========================================================================== */

/*
 * COLORS
 */



/*
 * MODULE
 */

.bn-images {
  > .bn-grid__row {
    > .bn-grid__col {
      font-size: 0;
    }
  }
  .bn-images__item {
    border-left: 2px solid transparent;
    border-right: 2px solid transparent;
    display: inline-block;
    overflow: hidden;
    width: 25%;
    img {
      height: 100%;
      width: 100%;
    }
    &:nth-child(n+5) {
      margin-top: 4px;
    }
  }
}


/* RESPONSIVE BEHAVIOR
 * --------------------------------------------------------------------------- */

// tablet
@media #{$mq__tablet} {
  .bn-images {
    .bn-images__item {
      margin-top: 0 !important;
      width: 50%;
      &:nth-child(n+3) {
        margin-top: 4px !important;
      }
    }
  }
}

// phone
@media #{$mq__phone} {
  .bn-images {
    .bn-images__item {
      border-left: 0;
      border-right: 0;
      margin-top: 5px !important;
      width: 100%;
      &:first-child {
        margin-top: 0 !important;
      }
    }
  }
}