/*  ==========================================================================
    MOBILE HEADER V2
    ========================================================================== */

@mixin mobile-header--v2() {
  @include mobile-header--v1();
  // controls
  .bn-controls {
    .bn-controls__left {
      order: 2;
      .bn-controls__left___top,
      .bn-controls__left___bottom {
        > .bn-grid__col {
          align-items: flex-end;
        }
      }
    }
    .bn-controls__right {
      align-items: flex-start;
      order: 1;
    }
  }
  // lang menu
  .bn-lang-menu {
    &.bn-lang-menu--above {
      ul {
        margin-left: 0;
        margin-right: -5px;
      }
    }
  }
  // control bar
  .bn-control-bar {
    .bn-control-bar__top,
    .bn-control-bar__center,
    .bn-control-bar__bottom {
      margin-left: calc(100% - #{$unit__wdt--mobile-header-control-bar});
    }
  }
  // minimized
  &.bn-mobile-header--minimized {
    // burger menu
    .bn-burger-menu {
      border-left-color: $clr__ci--primary;
      border-right-color: transparent;
      left: auto;
      right: 0;
    }
  }
}