/*  ==========================================================================
    GALLERY
    ========================================================================== */

/*
 * COLORS
 */



/*
 * MODULE
 */

.bn-gallery {
  .bn-gallery__frame {
    @include clearfix();
    .bn-gallery__image {
      float: left;
      padding: 5px;
      width: 33.33%;
      > div {
        overflow: hidden;
      }
      img {
        @include img--responsive();
      }
    }
  }
  .bn-gallery__foot {
    margin-top: 40px;
    text-align: center;
    .bn-gallery__more {
      color: $clr__ci--primary;
      cursor: pointer;
      font-size: 5.0em;
      &:hover {
        color: $clr__ci--secondary;
      }
    }
  }
}


/* RESPONSIVE BEHAVIOR
 * --------------------------------------------------------------------------- */

// phablet
@media #{$mq__phablet} {
  .bn-gallery {
    .bn-gallery__frame {
      .bn-gallery__image {
        width: 50%;
      }
    }
    .bn-gallery__foot {
      margin-top: 20px;
    }
  }
}

// phone
@media #{$mq__phone} {
  .bn-gallery {
    .bn-gallery__frame {
      .bn-gallery__image {
        float: none;
        width: 100%;
      }
    }
  }
}