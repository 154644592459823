/*  ==========================================================================
    QUOTE V4
    ========================================================================== */

/*
 * COLORS
 */

$module-clr__quote-v4--border: $clr__ci--primary;
$module-clr__quote-v4--signet: $clr__ci--primary;
$module-clr__quote-v4--text: $clr__ci--primary;

/*
 * MODULE
 */

.bn-quote-v4 {
  .bn-quote-v4__frame {
    border-bottom: 1px solid $module-clr__quote-v4--border;
    border-top: 1px solid $module-clr__quote-v4--border;
    margin: 0 auto;
    max-width: 500px;
    padding: 20px 40px;
    position: relative;
    .bn-quote-v4__quotation-mark {
      margin-bottom: 10px;
      text-align: center;
      .bn-icon {
        color: $module-clr__quote-v4--signet;
        font-size: 3.5em;
      }
    }
    .bn-quote-v4__text {
      @include typo--quote($module-clr__quote-v4--text);
      text-align: center;
    }
    .bn-quote-v4__author {
      @include typo--quote-author($module-clr__quote-v4--text);
      margin-top: 10px;
      text-align: center;
    }
  }
}


/* RESPONSIVE BEHAVIOR
 * --------------------------------------------------------------------------- */

// phablet
@media #{$mq__phablet} {
  .bn-quote-v4 {
    .bn-quote-v4__frame {
      padding: 20px;
      .bn-quote-v4__quotation-mark {
        .bn-icon {
          font-size: 2.5em;
        }
      }
    }
  }
}