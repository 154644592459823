/*  ==========================================================================
    CUSTOMER REVIEW
    ========================================================================== */

/*
 * COLORS
 */

$module-clr__review--bg: $clr__ci--primary;
$module-clr__review-typo: $clr__white;

.bn-reviews {
  display: flex;
  flex-wrap: wrap;
  .bn-item {
    background: $module-clr__review--bg;
    margin: 10px;
    padding: 20px;
    color: $module-clr__review-typo;
    font-size: 20px;
    width: calc(50% - 20px);
    &__rating {
      border-bottom: 1px solid;
      padding: 7px 0;
      .bn-rating {
        display: flex;

        &__stars {
          span {
            padding-right: 4px;
          }
        }
      }
      .bn-comment {
        font-style: italic;
      }
      &:last-of-type {
        border-bottom: 0;
      }
    }
  }
}

@media (max-width: 768px) {
  .bn-reviews {
    .bn-item {
      width: 100%;
      margin: 10px 0;
    }
  }
}