.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50.09%,-50.09%);
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  background: rgba(#000, 0.7);
  z-index: 999999;
  backdrop-filter: blur(5px);
  display: none;
  &.js-act {
    display: flex;
  }
  &_wrapper {
    background: #fff;
    padding: 50px;
    position: relative;
    max-width: 800px;
    width: 100%;
    margin: 0 20px;

    a {
      color: #4a4a4a;
      &:hover {
        color: darken(#4a4a4a, 25%);
      }
    }

    .closetrigger {
      position: absolute;
      top: 20px;
      right: 20px;
      cursor: pointer;
      .icon-x{
        position: relative;
        display: block;
        width: 24px;
        height: 24px;
        &:before,
        &:after {
          position: absolute;
          left: 15px;
          content: ' ';
          height: 20px;
          width: 2px;
          background-color: #333;
        }
        &:before {
          transform: rotate(45deg);
        }
        &:after {
          transform: rotate(-45deg);
        }
      }
    }

    .content {
      &_title {
        font-size: 30px;
        margin-bottom: 20px;
        line-height: 37px;
        text-transform: uppercase;
      }
      &_text {
        font-size: 1.5rem;
        line-height: 3.6rem;
        font-family: cursive;
        text-align: center;
        b {
          font-weight: bold;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .popup {
    &_wrapper {
      padding: 40px 25px;
      .content {
        &_title {
          font-size: 20px;
          line-height: 27px;
        }

        &_text {
          font-size: 1.4rem;
          line-height: 2rem;
        }
      }
    }
  }
}