/*  ==========================================================================
    LINKBOXES
    ========================================================================== */

/*
 * COLORS
 */

$module-clr__linkboxes--color: $clr__typo--secondary;
$module-clr__linkboxes--bg: $clr__ci--primary;

/*
 * MODULE
 */

.bn-linkboxes-v2 {
  .bn-linkboxes__list {
    display: flex;
    justify-content: center;
    .bn-linkboxes__item {
      display: inline-block;
      position: relative;
      margin-bottom: 20px;
      margin-right: 20px;
      height: 200px;
      &:last-child {
        margin-right: 0;
      }
      .bn-linkboxes__image {
        display: block;
        height: 100%;
        > div {
          overflow: hidden;
          height: 100%;
        }
        img {
          @include img--cover-v2();
        }
      }
      .bn-linkboxes__title {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        width: 100%;
        height: 100%;
        background: rgba($module-clr__linkboxes--bg, 0.5);
        transition: background .2s;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        > span {
          font-size: 2.5em;
          color: $clr__typo--secondary;
          text-transform: uppercase;
          padding: 0 10px;
        }
      }
      &:hover {
        .bn-linkboxes__title {
          background: $module-clr__linkboxes--bg;
        }
      }
    }
  }
}


/* RESPONSIVE BEHAVIOR
 * --------------------------------------------------------------------------- */

// small desktop
@media #{$mq__desktop--small} {
  .bn-linkboxes-v2 {
    .bn-linkboxes__list {
      justify-content: flex-start;
      flex-wrap: wrap;
      .bn-linkboxes__item {
        flex: 0 0 calc(33.3% - 14px);
        &:nth-child(3n) {
          margin-right: 0;
        }
      }
    }
  }
}
// tablet
@media #{$mq__tablet} {
  .bn-linkboxes-v2 {
    .bn-linkboxes__list {
      .bn-linkboxes__item {
        flex: 0 0 calc(50% - 10px);
        &:nth-child(3n) {
          margin-right: 20px;
        }
        &:nth-child(2n) {
          margin-right: 0;
        }
      }
    }
  }
}
// phablet
@media #{$mq__phablet} {
  .bn-linkboxes-v2 {
    .bn-linkboxes__list {
      .bn-linkboxes__item {
        flex: 0 0 calc(100%);
        height: auto;
        &:nth-child(3n) {
          margin-right: 0;
        }
      }
    }
  }
}