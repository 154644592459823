/*  ==========================================================================
    QUICK REQUEST HORIZONTAL
    ========================================================================== */

@mixin quick-request--horizontal() {
  .bn_quickrequest_horizontal:not(.bn-dynformInit)>div:not(.bn-loader){
    display: none!important;
  }

  .bn_quickrequest_horizontal{
    width: 100%;
  }
}