/*  ==========================================================================
    QUICK REQUEST V1
    ========================================================================== */

@mixin quick-request--v1() {
  position: fixed;
  bottom: 0;
  right: 0;
  height: 0;
  overflow: hidden;
  width: 100%;
  pointer-events: none;
  z-index: $unit__zi--quick-request;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  transition: height 0.5s;

  .bn-quick-request--popup {
    position: absolute;
    bottom: 80px;
    right: 30px;
    width: 100%;
    max-width: 70em !important;
    padding: 0 5em 2em !important;
    background-color: #938E83;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.5s;
    border-radius: 5px;
    &.JS-open {
      pointer-events: all;
      opacity: 1;
    }
  }
  .bn-quick-request__form {
    align-items: center;
    display: flex;
  }
  .bn-quick-request--trigger {
    width: 200px;
    background: #701236;
    min-height: 60px;
    pointer-events: all;
    color: #FFFFFF;
    font-size: 1.6em;
    text-transform: uppercase;
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
    padding: 10px;
    margin-right: 30px;
    cursor: pointer;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    &.JS-open {
      i {
        &:nth-child(2) {
          display: none;
        }
        &:last-child {
          display: block;
        }
      }
    }
    i {
      &:last-child {
        display: none;
      }
    }
  }
  &.bn-quick-request--small {
    width: calc(100% - #{$unit__wdt--header-v3-sidebar-desktop-large});
  }
  &.bn-quick-request--visible {
    height: $unit__hgt--quick-request-v1;
    overflow: visible;
  }

  .bn-form-quickrequest-container {
    .ractive--segmentsTemplate {
      .bn-form__flex {
        background-color: #938E83;
        color: #FFFFFF;
        border-radius: 5px;
      }
      .bn-form__block--segments {
        border: none;
      }
      .bn-form__checkbox label {
        color: #FFFFFF;
        font-size: 2.5em;
        cursor: pointer;
      }
    }
  }

  /*
   * RESPONSIVE BEHAVIOR
   */

  // desktop medium
  @media #{$mq__desktop--medium} {
    &.bn-quick-request--small {
      width: calc(100% - #{$unit__wdt--header-v3-sidebar});
    }
  }

  // tablet
  @media #{$mq__tablet} {
    display: none;
  }
}