/*  ==========================================================================
    DOWNLOAD-LIST
    ========================================================================== */

/*
 * COLORS
 */

$module-clr__download-list--link: $clr__typo--primary;
$module-clr__download-list--accent: $clr__ci--primary;

/*
 * MODULE
 */

.bn-download-list {
  > .bn-grid__row {
    justify-content: space-between;
  }
  .bn-download-list__image,
  .bn-download-list__files {
    flex: none !important;
  }
  .bn-download-list__image {
    width: calc(50% - 10px);
    > a {
      display: block;
      overflow: hidden;
    }
    img {
      @include img--responsive();
    }
    + .bn-download-list__files {
      align-items: center;
      display: flex;
      width: calc(50% - 10px);
      > div {
        max-width: 100%;
      }
    }
  }
  .bn-download-list__files {
    @include typo--paragraph($module-clr__download-list--link,$module-clr__download-list--accent);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    > div {
      flex-grow: 1;
      max-width: calc(50% - 10px);
      padding: 5px 0;
      width: 100%;
      .bn-download-list__file {
        color: $module-clr__download-list--link;
        display: inline-block;
        .bn-icon {
          color: $module-clr__download-list--accent;
          margin-right: 10px;
        }
        &:visited {
          color: $module-clr__download-list--link;
        }
        &:hover {
          color: $module-clr__download-list--accent;
        }
      }
    }
  }
}


/* RESPONSIVE BEHAVIOR
 * --------------------------------------------------------------------------- */

// phablet
@media #{$mq__phablet} {
  .bn-download-list {
    .bn-download-list__image,
    .bn-download-list__files {
      width: 100% !important;
    }
    .bn-download-list__image {
      margin-top: 10px;
      order: 2;
    }
    .bn-download-list__files {
      display: block;
      order: 1;
      > div {
        max-width: 100%;
      }
    }
  }
}