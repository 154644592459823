/*  ==========================================================================
    IMPRESSIONS
    ========================================================================== */

/*
 * COLORS
 */

$module-clr__impressions--arrows: $clr__ci--primary;
$module-clr__impressions--arrows-hover: $clr__ci--secondary;

/*
 * MODULE
 */

.bn-impressions {
  .bn-impressions__arrows {
    @include slider--arrows-v2($module-clr__impressions--arrows,$module-clr__impressions--arrows-hover);
    margin-bottom: 0 !important;
  }
  .bn-impressions__head{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin-bottom: 20px;
    &.bn-titleset{
      justify-content: space-between;
    }
  }
  .bn-impressions__title{
    text-transform: uppercase;
    font-size: 3em;
    font-weight: $typo__fw--regular;
  }
  .bn-impressions__slider {
    overflow: hidden;
    .bn-impressions__image {
      display: block;
      > div {
        overflow: hidden;
      }
      img {
        @include img--responsive();
      }
    }
  }
}


/* RESPONSIVE BEHAVIOR
 * --------------------------------------------------------------------------- */
@media #{$mq__phone}{
  .bn-impressions {
    .bn-impressions__title{
      font-size: 2em;
    }
  }
}

@media  (max-width: 350px){
  .bn-impressions {
    .bn-impressions__title{
      font-size: 1.8em;
    }
  }
}