/*  ==========================================================================
    CLICK TO PAY
    ========================================================================== */

.bn-click-to-pay {
  .bn-form {
    color: $clr__ci--primary;
    margin: 0 auto;
    max-width: 480px;
    a {
      color: $clr__ci--primary;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
    label,
    input,
    textarea {
      &:not([type='checkbox']) {
        display: block;
        font-size: 1.6em;
        width: 100%;
      }
    }
    input,
    textarea {
      &:not([type='checkbox']):not([type='submit']) {
        background-color: $clr__white;
        border-bottom: 1px solid $clr__ci--primary;
        border-radius: 0;
      }
    }
    label {
      &:not([for='privacy']) {
        margin-bottom: 0.5em;
        &.js-not-valid {
          color: $clr__error !important;
        }
      }
    }
    input {
      &:not([type='checkbox']) {
        height: 2em;
        padding: 0.5em;
        border-top: none;
        border-left: none;
        border-right: none;
        &.js-not-valid {
          border-color: $clr__error !important;
        }
      }
      &[type="submit"] {
        background-color: $clr__ci--primary;
        border: 1px solid $clr__ci--primary;
        color: $clr__white;
        cursor: pointer;
        text-transform: uppercase;
        height: auto;
        &:hover {
          background-color: transparent;
          color: $clr__ci--primary;
        }
      }
      &[type="checkbox"] {
        margin-right: 0.5em;
        &.js-not-valid {
          + label {
            color: $clr__error !important;
            font-weight: bold;
            > * {
              color: $clr__error !important;
            }
          }
        }
      }
    }
    textarea {
      min-height: 8em;
      padding: 0.5em;
    }
  }
  .bn-block {
    box-shadow: 0 0.2em 0.6em 0.2em rgba($clr__ci--primary, 0.15);
    &--hidden {
      display: none;
    }
  }
  .bn-field {
    padding: 1.0em 2.0em;
    &--title {
      border-bottom: 0.5em solid $clr__ci--primary;
    }
    &--mail {
      padding-top: 2.0em;
    }
    &--privacy {
      align-items: center;
      display: flex;
    }
    &--mandatory {
      font-size: 1.0em;
      padding-bottom: 2.0em;
    }
  }
  .bn-title {
    font-size: 2.0em;
    text-transform: uppercase;
  }
  .bn-help {
    font-size: 1.3em;
    margin-top: 1em;
  }
}
