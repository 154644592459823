/*  ==========================================================================
    IMPRESSIONS V3
    ========================================================================== */

/*
 * COLORS
 */

$module-clr__impressions-v3--arrows: $clr__ci--primary;
$module-clr__impressions-v3--arrows-hover: $clr__ci--secondary;

/*
 * MODULE
 */

.bn-impressions-v3 {
  .bn-impressions-v3__arrows {
    @include slider--arrows-v2($module-clr__impressions-v3--arrows,$module-clr__impressions-v3--arrows-hover);
    margin-bottom: 0 !important;
  }
  .bn-impressions-v3__head{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin-bottom: 20px;
    &.bn-titleset{
      justify-content: space-between;
    }
  }
  .bn-impressions-v3__title{
    text-transform: uppercase;
    font-size: 3em;
    font-weight: $typo__fw--regular;
    padding-left: 10px;
  }
  .bn-impressions-v3__slider {
    @include clearfix();
    overflow: hidden;
    .bn-impressions-v3__image {
      display: block;
      float: left;
      padding: 0 10px;
      width: 33.33%;
      > div {
        overflow: hidden;
      }
      img {
        @include img--responsive();
      }
    }
  }
}


/* RESPONSIVE BEHAVIOR
 * --------------------------------------------------------------------------- */

// phablet
@media #{$mq__phablet} {
  .bn-impressions-v3 {
    .bn-impressions-v3__title{
      font-size: 2em;
      padding-left: 0;
    }
    .bn-impressions-v3__slider {
      .bn-impressions-v3__image {
        padding: 0 5px;
        width: 50%;
      }
    }
  }
}

// phone
@media #{$mq__phone} {
  .bn-impressions-v3 {
    .bn-impressions-v3__slider {
      .bn-impressions-v3__image {
        float: none;
        padding: 0;
        width: 100%;
      }
    }
  }
}

@media  (max-width: 350px){
  .bn-impressions-v3 {
    .bn-impressions-v3__title{
      font-size: 1.8em;
    }
  }
}