/*  ==========================================================================
    IMAGE
    ========================================================================== */

// responsive image
@mixin img--responsive() {
  display: block;
  height: auto;
  width: 100%;
}

// cover image (percentage)
@mixin img--cover($size) {
  display: block;
  height: auto;
  left: 50%;
  max-height: none;
  max-width: none;
  min-height: $size * 1%;
  min-width: $size * 1%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: auto;
}

// cover image (viewport)
@mixin img--cover-viewport($size) {
  @include img--cover($size);
  left: 50vw;
  min-height: $size * 1vh;
  min-width: $size * 1vw;
  top: 50vh;
}

// cover image reset
@mixin img--cover-reset() {
  display: initial;
  left: auto;
  max-height: 100%;
  max-width: 100%;
  min-height: 0;
  min-width: 0;
  position: static;
  top: auto;
  transform: translate(0, 0);
}

// cover image v2 (needs "object-fit-images" polyfill for older browser like f.e. ie11)
@mixin img--cover-v2 () {
  font-family: 'object-fit: cover;';
  height: 100%;
  object-fit: cover;
  width: 100%;
}

// image crisp edges rendering
@mixin img--rendering-crisp-edges() {
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
}