/*  ==========================================================================
    TEXT PIC BOX V3
    ========================================================================== */

/*
 * COLORS
 */

$module-clr__text-pic-box-v3--bg-primary: $clr__ci--primary;
$module-clr__text-pic-box-v3--bg-secondary: $clr__ci--secondary;
$module-clr__text-pic-box-v3--bg-tertiary: $clr__ci--tertiary;
$module-clr__text-pic-box-v3--typo-primary: $clr__typo--secondary;
$module-clr__text-pic-box-v3--typo-secondary: $clr__typo--secondary;
$module-clr__text-pic-box-v3--typo-tertiary: $clr__typo--primary;
$module-clr__text-pic-box-v3--accent-primary: $clr__ci--secondary;
$module-clr__text-pic-box-v3--accent-secondary: $clr__ci--primary;
$module-clr__text-pic-box-v3--accent-tertiary: $clr__ci--primary;

/*
 * MODULE
 */

.bn-text-pic-box-v3 {
  .bn-text-pic-box-v3__frame {
    justify-content: space-between;
    .bn-text-pic-box-v3__content,
    .bn-text-pic-box-v3__images {
      display: flex;
      max-width: 50%;
      > div {
        width: 100%;
      }
    }
    .bn-text-pic-box-v3__content {
      align-items: flex-end;
      padding: 100px 0 50px 0;
      > div {
        padding: 40px;
        position: relative;
        &:after {
          content: '';
          display: block;
          height: 100%;
          margin-right: -150px;
          position: absolute;
          right: 0;
          top: 0;
          width: 150px;
        }
      }
      .bn-text-pic-box-v3__headline {
        margin-bottom: 40px;
      }
      .bn-text-pic-box-v3__text {
        @include typo--table-lock();
      }
      .bn-text-pic-box-v3__button {
        margin-top: 20px;
      }
    }
    .bn-text-pic-box-v3__images {
      align-items: flex-start;
      overflow: hidden;
      position: relative;
      z-index: 5;
      > div {
        align-items: stretch;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;
        margin-top: -20px;
      }
      .bn-text-pic-box-v3__image {
        display: block;
        padding-top: 20px;
        img {
          @include img--responsive();
        }
        > div {
          overflow: hidden;
        }
      }
      // output according to number of pictures
      &.bn-text-pic-box-v3__images--number-1 {
        .bn-text-pic-box-v3__image {
          width: 100%;
        }
      }
      &.bn-text-pic-box-v3__images--number-2 {
        .bn-text-pic-box-v3__image {
          width: calc(50% - 10px);
          &:first-child {
            width: 100%;
          }
        }
      }
    }
  }
  // colors
  &.bn-text-pic-box-v3--clr-primary {
    .bn-text-pic-box-v3__frame {
      .bn-text-pic-box-v3__content {
        > div {
          background-color: $module-clr__text-pic-box-v3--bg-primary;
          &:after {
            background-color: $module-clr__text-pic-box-v3--bg-primary;
          }
        }
        .bn-text-pic-box-v3__headline {
          @include typo--subheadline($module-clr__text-pic-box-v3--typo-primary);
        }
        .bn-text-pic-box-v3__text {
          @include typo--rte($module-clr__text-pic-box-v3--typo-primary,$module-clr__text-pic-box-v3--accent-primary);
        }
        .bn-text-pic-box-v3__button {
          .bn-button {
            @include button--custom(transparent,$module-clr__text-pic-box-v3--typo-primary,$module-clr__text-pic-box-v3--typo-primary,transparent,$module-clr__text-pic-box-v3--accent-primary,$module-clr__text-pic-box-v3--accent-primary);
          }
        }
      }
    }
  }
  &.bn-text-pic-box-v3--clr-secondary {
    .bn-text-pic-box-v3__frame {
      .bn-text-pic-box-v3__content {
        > div {
          background-color: $module-clr__text-pic-box-v3--bg-secondary;
          &:after {
            background-color: $module-clr__text-pic-box-v3--bg-secondary;
          }
        }
        .bn-text-pic-box-v3__headline {
          @include typo--subheadline($module-clr__text-pic-box-v3--typo-secondary);
        }
        .bn-text-pic-box-v3__text {
          @include typo--rte($module-clr__text-pic-box-v3--typo-secondary,$module-clr__text-pic-box-v3--accent-secondary);
        }
        .bn-text-pic-box-v3__button {
          .bn-button {
            @include button--custom(transparent,$module-clr__text-pic-box-v3--typo-secondary,$module-clr__text-pic-box-v3--typo-secondary,transparent,$module-clr__text-pic-box-v3--accent-secondary,$module-clr__text-pic-box-v3--accent-secondary);
          }
        }
      }
    }
  }
  &.bn-text-pic-box-v3--clr-tertiary {
    .bn-text-pic-box-v3__frame {
      .bn-text-pic-box-v3__content {
        > div {
          background-color: $module-clr__text-pic-box-v3--bg-tertiary;
          &:after {
            background-color: $module-clr__text-pic-box-v3--bg-tertiary;
          }
        }
        .bn-text-pic-box-v3__headline {
          @include typo--subheadline($module-clr__text-pic-box-v3--typo-tertiary);
        }
        .bn-text-pic-box-v3__text {
          @include typo--rte($module-clr__text-pic-box-v3--typo-tertiary,$module-clr__text-pic-box-v3--accent-tertiary);
        }
        .bn-text-pic-box-v3__button {
          .bn-button {
            @include button--custom(transparent,$module-clr__text-pic-box-v3--typo-tertiary,$module-clr__text-pic-box-v3--typo-tertiary,transparent,$module-clr__text-pic-box-v3--accent-tertiary,$module-clr__text-pic-box-v3--accent-tertiary);
          }
        }
      }
    }
  }
  // mirror
  &.bn-text-pic-box-v3--mirror {
    .bn-text-pic-box-v3__frame {
      .bn-text-pic-box-v3__content {
        order: 2;
        > div {
          &:after {
            left: 0;
            margin-left: -150px;
            margin-right: 0;
            right: auto;
          }
        }
      }
      .bn-text-pic-box-v3__images {
        order: 1;
        // output according to number of pictures
        &.bn-text-pic-box-v3__images--number-2 {
          .bn-text-pic-box-v3__image {
            &:last-child {
              margin-left: auto;
            }
          }
        }
      }
    }
  }
  // spacing to next text pic element
  + .bn-text-pic-box-v3 {
    margin-top: 20px;
  }
}


/* RESPONSIVE BEHAVIOR
 * --------------------------------------------------------------------------- */

// tablet
@media #{$mq__tablet} {
  .bn-text-pic-box-v3 {
    .bn-text-pic-box-v3__frame {
      .bn-text-pic-box-v3__content {
        > div {
          padding-left: 20px;
          padding-right: 20px;
          &:after {
            margin-right: -100px;
            width: 100px;
          }
        }
        .bn-text-pic-box-v3__headline {
          margin-bottom: 20px;
        }
      }
    }
    &.bn-text-pic-box-v3--mirror {
      .bn-text-pic-box-v3__frame {
        .bn-text-pic-box-v3__content {
          > div {
            &:after {
              margin-left: -100px;
            }
          }
        }
      }
    }
  }
}

// phablet
@media #{$mq__phablet} {
  .bn-text-pic-box-v3 {
    .bn-text-pic-box-v3__frame {
      display: block;
      padding-left: 0 !important;
      padding-right: 0 !important;
      .bn-text-pic-box-v3__content,
      .bn-text-pic-box-v3__images {
        display: block;
        max-width: 100%;
      }
      .bn-text-pic-box-v3__content {
        padding: 40px;
        > div {
          background-color: transparent !important;
          padding: 0;
          &:after {
            display: none;
          }
        }
      }
      .bn-text-pic-box-v3__images {
        > div {
          margin-top: 0;
        }
        .bn-text-pic-box-v3__image {
          display: none;
          padding-top: 10px;
          width: calc(50% - 5px) !important;
          &:nth-child(-n+2){
            display: block;
          }
          &:first-child:nth-last-child(1) {
            width: 100% !important;
          }
        }
      }
    }
    // colors
    &.bn-text-pic-box-v3--clr-primary {
      .bn-text-pic-box-v3__frame {
        .bn-text-pic-box-v3__content {
          background-color: $module-clr__text-pic-box-v3--bg-primary;
        }
      }
    }
    &.bn-text-pic-box-v3--clr-secondary {
      .bn-text-pic-box-v3__frame {
        .bn-text-pic-box-v3__content {
          background-color: $module-clr__text-pic-box-v3--bg-secondary;
        }
      }
    }
    &.bn-text-pic-box-v3--clr-tertiary {
      .bn-text-pic-box-v3__frame {
        .bn-text-pic-box-v3__content {
          background-color: $module-clr__text-pic-box-v3--bg-tertiary;
        }
      }
    }
    // spacing to next text pic element
    + .bn-text-pic-box-v3 {
      margin-top: 10px;
    }
  }
}

// phone
@media #{$mq__phone} {
  .bn-text-pic-box-v3 {
    .bn-text-pic-box-v3__frame {
      .bn-text-pic-box-v3__content {
        padding: 20px;
      }
    }
  }
}