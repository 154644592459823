/*  ==========================================================================
    DIVIDER
    ========================================================================== */

/*
 * COLORS
 */

$module-clr__divider--line: $clr__ci--primary;
$module-clr__divider--signet: $clr__ci--primary;

/*
 * MODULE
 */

.bn-divider {
  > .bn-grid__row {
    > .bn-grid__col {
      min-height: 2px;
      position: relative;
    }
  }
  .bn-divider__line {
    background-color: $module-clr__divider--line;
    height: 2px;
    left: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
  }
  .bn-divider__signet {
    position: relative;
    text-align: center;
    z-index: 5;
    .bn-icon {
      background-color: $clr__white;
      color: $module-clr__divider--signet;
      font-size: 6.5em;
      padding: 0 5px;
    }
  }
}


/* RESPONSIVE BEHAVIOR
 * --------------------------------------------------------------------------- */

// phablet
@media #{$mq__phablet} {
  .bn-divider {
    .bn-divider__signet {
      .bn-icon {
        font-size: 2.5em;
      }
    }
  }
}