/*  ==========================================================================
    ROOMS LIST VIEW V2
    ========================================================================== */

/*
 * COLORS
 */

$module-clr__rooms-list-view-v2--bg-primary: $clr__ci--primary;
$module-clr__rooms-list-view-v2--bg-secondary: $clr__white;
$module-clr__rooms-list-view-v2--border-primary: $clr__ci--primary;
$module-clr__rooms-list-view-v2--border-secondary: $clr__ci--primary;
$module-clr__rooms-list-view-v2--button-primary: $clr__typo--secondary;
$module-clr__rooms-list-view-v2--button-secondary: $clr__ci--primary;
$module-clr__rooms-list-view-v2--price-primary: $clr__ci--primary;
$module-clr__rooms-list-view-v2--price-secondary: $clr__white;
$module-clr__rooms-list-view-v2--price-typo-primary: $clr__typo--secondary;
$module-clr__rooms-list-view-v2--price-typo-secondary: $clr__ci--primary;
$module-clr__rooms-list-view-v2--typo-primary: $clr__typo--secondary;
$module-clr__rooms-list-view-v2--typo-secondary: $clr__ci--primary;

/*
 * MODULE
 */

.bn-rooms-list-view-v2 {
  .bn-rooms-list-view-v2__tabs {
    padding-bottom: 70px;
    text-align: center;
    > div {
      display: inline-block;
      padding: 10px;
    }
    .bn-button {
      @include button--reset();
      @include button--default-inverted();
      &.bn-button--act {
        background-color: $clr__ci--primary !important;
        border-color: $clr__ci--primary !important;
        color: $clr__typo--secondary !important;
      }
    }
  }
  .bn-rooms-list-view-v2__holder {
    display: flex;
    flex-wrap: wrap;
    .bn-room-box-v2 {
      background-color: $module-clr__rooms-list-view-v2--bg-primary;
      border-bottom: 2px solid $module-clr__rooms-list-view-v2--border-primary;
      color: $module-clr__rooms-list-view-v2--typo-primary;
      margin: 40px 0 0 15px;
      position: relative;
      width: calc(33.33% - 10px);
      a {
        color: $module-clr__rooms-list-view-v2--typo-primary;
        &:visited {
          color: $module-clr__rooms-list-view-v2--typo-primary;
        }
        &:hover {
          color: $module-clr__rooms-list-view-v2--typo-primary;
        }
      }
      .bn-room-box-v2__image {
        background-color: $clr__black;
        display: block;
        overflow: hidden;
        position: relative;
        img {
          @include img--responsive();
        }
        .bn-room-box-v2__price {
          background-color: $module-clr__rooms-list-view-v2--price-primary;
          border-bottom: 2px solid $module-clr__rooms-list-view-v2--price-typo-primary;
          color: $module-clr__rooms-list-view-v2--price-typo-primary;
          display: inline-block;
          height: 100px;
          left: 20px;
          min-width: 100px;
          overflow: hidden;
          position: absolute;
          top: 20px;
          z-index: 5;
          > div {
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: center;
            padding: 10px;
            position: relative;
            text-align: center;
          }
          .bn-room-box-v2__amount-addition {
            font-size: 1.4em;
          }
          .bn-icon {
            font-size: 1.6em;
            margin-right: 5px;
          }
          .bn-room-box-v2__amount {
            font-size: 2.0em;
          }
        }
      }
      .bn-room-box-v2__content {
        padding: 60px 40px 140px 40px;
        text-align: center;
        .bn-room-box-v2__name {
          @include typo--subheadline($module-clr__rooms-list-view-v2--typo-primary);
        }
        .bn-room-box-v2__stats {
          font-size: 1.6em;
          margin-top: 20px;
        }
        .bn-room-box-v2__button {
          bottom: 40px;
          left: 0;
          padding: 0 40px;
          position: absolute;
          width: 100%;
          z-index: 5;
          .bn-button {
            @include button--custom($module-clr__rooms-list-view-v2--button-primary,$module-clr__rooms-list-view-v2--button-primary,$module-clr__rooms-list-view-v2--bg-primary,transparent,$module-clr__rooms-list-view-v2--button-primary,$module-clr__rooms-list-view-v2--button-primary);
          }
        }
      }
      // remove margin left on first and every fourth box
      &:first-child,
      &:nth-child(3n+4) {
        margin-left: 0;
      }
      // remove margin top on first 3 boxes
      &:nth-child(-n+3) {
        margin-top: 0;
      }
      // recolor every second box
      &:nth-child(even) {
        background-color: $module-clr__rooms-list-view-v2--bg-secondary;
        border-color: $module-clr__rooms-list-view-v2--border-secondary;
        color: $module-clr__rooms-list-view-v2--typo-secondary;
        a {
          color: $module-clr__rooms-list-view-v2--typo-secondary;
          &:visited {
            color: $module-clr__rooms-list-view-v2--typo-secondary;
          }
          &:hover {
            color: $module-clr__rooms-list-view-v2--typo-secondary;
          }
        }
        .bn-room-box-v2__image {
          .bn-room-box-v2__price {
            background-color: $module-clr__rooms-list-view-v2--price-secondary;
            border-color: $module-clr__rooms-list-view-v2--price-typo-secondary;
            color: $module-clr__rooms-list-view-v2--price-typo-secondary;
          }
        }
        .bn-room-box-v2__content {
          .bn-room-box-v2__name {
            @include typo--subheadline($module-clr__rooms-list-view-v2--typo-secondary);
          }
          .bn-room-box-v2__button {
            .bn-button {
              @include button--custom($module-clr__rooms-list-view-v2--button-secondary,$module-clr__rooms-list-view-v2--button-secondary,$module-clr__rooms-list-view-v2--bg-secondary,transparent,$module-clr__rooms-list-view-v2--button-secondary,$module-clr__rooms-list-view-v2--button-secondary);
            }
          }
        }
      }
    }
  }
  &.bn-rooms-list-view-v2--filtered {
    .bn-rooms-list-view-v2__holder {
      .bn-room-box-v2 {
        display: none;
        &.bn-room-box-v2--act {
          display: block;
        }
      }
    }
  }
}


/* RESPONSIVE BEHAVIOR
 * --------------------------------------------------------------------------- */

// laptop
@media #{$mq__laptop} {
  .bn-rooms-list-view-v2 {
    .bn-rooms-list-view-v2__holder {
      .bn-room-box-v2 {
        .bn-room-box-v2__content {
          padding-bottom: 130px;
          .bn-room-box-v2__button {
            .bn-button {
              min-width: 0 !important;
              width: 100% !important;
            }
          }
        }
      }
    }
  }
}

// tablet
@media #{$mq__tablet} {
  .bn-rooms-list-view-v2 {
    .bn-rooms-list-view-v2__holder {
      .bn-room-box-v2 {
        margin: 40px 0 0 20px !important;
        width: calc(50% - 10px);
        // remove margin left on first and every third box
        &:first-child,
        &:nth-child(2n+3) {
          margin-left: 0 !important;
        }
        // remove margin top on first 2 boxes
        &:nth-child(-n+2) {
          margin-top: 0 !important;
        }
      }
    }
  }
}

// phablet
@media #{$mq__phablet} {
  .bn-rooms-list-view-v2 {
    .bn-rooms-list-view-v2__tabs {
      padding-bottom: 30px;
      > div {
        display: block;
      }
    }
    .bn-rooms-list-view-v2__holder {
      .bn-room-box-v2 {
        margin: 0 0 20px 0 !important;
        width: 100%;
        .bn-room-box-v2__image {
          .bn-room-box-v2__price {
            border-bottom-width: 1px;
            display: block;
            height: auto;
            left: auto;
            min-width: 0;
            position: relative;
            top: auto;
            br {
              display: none;
            }
            .bn-room-box-v2__amount-addition {
              margin: 0 5px;
            }
            .bn-room-box-v2__amount {
              font-weight: $typo__fw--bold;
            }
          }
        }
        .bn-room-box-v2__content {
          padding: 20px 20px 90px 20px;
          .bn-room-box-v2__button {
            bottom: 20px;
            padding: 0 20px;
          }
        }
        // remove margin bottom from last child
        &:last-child {
          margin-bottom: 0 !important;
        }
      }
    }
  }
}

// phone
@media #{$mq__phone} {
  .bn-rooms-list-view-v2 {
    .bn-rooms-list-view-v2__tabs {
      padding-bottom: 15px;
      > div {
        padding: 5px;
      }
    }
  }
}